import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Users from "./pages/Users";
import UserStocks from "./pages/UserStocks";

import "./App.css";

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <Users />
    //   </header>
    // </div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user/:userId" element={<UserStocks />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
