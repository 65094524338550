import React from "react";
import './Users.css'


export default function Users() {
  const DUMMY_USERS = [
    {
      id: "u0001",
      name: "John Doe",
      stocks: [
        {
          title: "REL",
          rate: +2112.99
        },
        {
          title: "APPL",
          rate: +5122.99
        },
        {
          title: "MRF",
          rate: +51112.99
        },
      ]
    },
    {
      id: "u0002",
      name: "Kaustubh Tripathi",
      stocks: [
        {
          title: "REL",
          rate: +2112.99
        },
        {
          title: "PMPL",
          rate: +282.99
        },
        {
          title: "SAIL",
          rate: +86.99
        },
      ]
    },
    {
      id: "u0003",
      name: "Mudit",
      stocks: [
        {
          title: "HAL",
          rate: +3132.99
        },
        {
          title: "PMPL",
          rate: +282.99
        },
        {
          title: "APPL",
          rate: +9386.99
        },
      ]
    }
  ]
  return (
    <div className="users__container">
      <h1 className="users__title">All Users</h1>
      <div className="users__grid">
        {DUMMY_USERS.map((user) => {
          return <div className="user__card">
            <p className="user__title">{user.name}</p>
            <a className="user__link" href={`/user/${user.id}`} alt><button>Show User Details</button></a>
          </div>
        })}
      </div>
    </div>
  );
}
