import React from 'react'
import './../App.css'
import './Home.css'

export default function Home() {
    return (
        <div className='home__container'>
            <h1 className='home__title'>Stock Recommendation System</h1>
            <p className='home__description'>
                Recommended stocks based on various factors.
                <br /><br /><br /><br /><br />
                Some description goes here
            </p>
            <div className='home__buttons'>
                <a href='/users'>
                    <button className='home__button'>
                        List Users
                    </button>
                </a>
                <a href='/stocks'>
                    <button className='home__button'>
                        List Stocks
                    </button>
                </a>
            </div>
        </div >
    )
}
