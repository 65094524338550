import React from 'react'
import { useParams } from 'react-router-dom'

import './UserStocks.css'

export default function UserStocks() {

    const DUMMY_USERS = [
        {
            id: "u0001",
            name: "John Doe",
            stocks: [
                {
                    title: "REL",
                    rate: +2112.99
                },
                {
                    title: "APPL",
                    rate: +5122.99
                },
                {
                    title: "MRF",
                    rate: +51112.99
                },
            ]
        },
        {
            id: "u0002",
            name: "Kaustubh Tripathi",
            stocks: [
                {
                    title: "REL",
                    rate: +2112.99
                },
                {
                    title: "PMPL",
                    rate: +282.99
                },
                {
                    title: "SAIL",
                    rate: +86.99
                },
            ]
        },
        {
            id: "u0003",
            name: "Mudit",
            stocks: [
                {
                    title: "HAL",
                    rate: +3132.99
                },
                {
                    title: "PMPL",
                    rate: +282.99
                },
                {
                    title: "APPL",
                    rate: +9386.99
                },
            ]
        }
    ]

    const userId = useParams().userId;
    const currentUser = DUMMY_USERS.filter((user) => user.id === userId)
    return (
        <div className='ustocks__container'>
            <h1 className='ustocks__title'>
                User Stocks
            </h1>
            <p className='ustocks__description'>Showing stocks for <b>{currentUser.map((user) => { return user.name })}</b></p>
        </div>
    )
}
